import React, { useCallback } from "react";
import Head from "next/head";
import { useRouter } from "next/router";

import Logo from "src/app/layouts/landing/Header/Logo";
import Button from "@/components/Button";

import useUser from "@/data/hooks/useUser";

interface Props {
  statusCode: number;
  title: string;
  description?: string;
  onReset?: () => void;
}

const Error: React.FC<Props> = ({ statusCode, title, onReset }) => {
  const router = useRouter();
  const { authenticated } = useUser();

  const handleClick = useCallback(() => {
    router.push(authenticated ? "/dashboard/orders" : "/", undefined);
  }, [authenticated]);

  return (
    <>
      <Head>
        <title>
          {statusCode} | {title}
        </title>
      </Head>
      <div className="flex h-screen flex-col bg-[#F4F5F7]">
        <div className="flex h-[85px] items-center border-b border-[#D8DEE2] bg-white px-8">
          <a href="/">
            <Logo className="w-[180px]" />
          </a>
        </div>
        <div className="flex flex-grow items-center justify-center">
          <div className="flex flex-col items-center p-8 text-center text-secondary">
            <div className="text-[129px] font-bold text-line at768:text-[262px] at768:leading-[328px]">
              {statusCode}
            </div>
            <div className="mt-6 text-2xl font-bold">{title}</div>
            <div className="mt-3 font-medium">Вы можете вернуться на главную страницу и попробовать еще раз</div>
            <div className="mt-11">
              <Button onClick={handleClick}>
                <span className="text-lg font-bold">На главную</span>
              </Button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default React.memo(Error);
