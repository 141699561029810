import React from "react";

const Logo = React.forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLDivElement>>(({ className }, ref) => (
  <div ref={ref} className={className}>
    <svg width="183" height="28" viewBox="0 0 183 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M24.3316 23.5634L15.0249 26.7334V17.5101L24.3316 14.3401V23.5634Z" fill="#2196F3" />
      <path d="M15.025 17.51L0 12.3933V21.6166L15.025 26.7333V17.51Z" fill="#0A3D8C" />
      <path d="M37.3601 18.7768L24.3318 14.3401V23.5634L37.3601 28.0001V18.7768Z" fill="#0A3D8C" />
      <path d="M46.6668 24.83L37.3601 28V18.7767L46.6668 15.6067V24.83Z" fill="#2196F3" />
      <path d="M33.6382 11.1701L24.3315 14.3401V5.11678L33.6382 1.94678V11.1701Z" fill="#2196F3" />
      <path d="M24.3316 5.11667L9.30664 0V9.22333L24.3316 14.34V5.11667Z" fill="#0A3D8C" />
      <path
        d="M76.8075 15.8088L74.4066 18.3779V23.0188H70.2917V4.6665H74.4066V13.2397L82.3262 4.6665H86.9257L79.5159 12.8467L87.3602 23.0188H82.5308L76.8075 15.8088Z"
        fill="#2196F3"
      />
      <path
        d="M88.7964 14.943V4.6665H92.9351V14.7858C92.9351 18.2723 94.4168 19.7153 96.9219 19.7153C99.4508 19.7153 100.907 18.2723 100.907 14.7858V4.6665H104.996V14.943C104.996 20.3465 101.981 23.3332 96.8955 23.3332C91.8101 23.3332 88.7964 20.3441 88.7964 14.943Z"
        fill="#2196F3"
      />
      <path
        d="M124.271 18.0107C124.271 21.1828 121.819 23.0188 117.118 23.0188H107.868V4.6665H116.605C121.077 4.6665 123.376 6.57984 123.376 9.43756C123.376 11.2723 122.457 12.6883 121 13.4755C122.994 14.1312 124.271 15.7032 124.271 18.0107ZM111.982 7.8644V12.1909H116.096C118.114 12.1909 119.207 11.4541 119.207 10.0148C119.207 8.57545 118.109 7.8644 116.096 7.8644H111.982ZM120.107 17.5662C120.107 16.02 118.957 15.2856 116.811 15.2856H111.982V19.8209H116.811C118.957 19.8197 120.107 19.1381 120.107 17.5649V17.5662Z"
        fill="#2196F3"
      />
      <path
        d="M140.036 19.6097V23.0188H126.188V4.6665H139.704V8.0744H130.301V12.0595H138.605V15.363H130.301V19.6097H140.036Z"
        fill="#2196F3"
      />
      <path
        d="M153.347 23.0237L149.897 17.9112H146.09V23.0237H141.95V4.6665H149.693C154.471 4.6665 157.46 7.20984 157.46 11.3251C157.46 14.0784 156.105 16.0974 153.781 17.1191L157.792 23.0139L153.347 23.0237ZM149.463 8.12721H146.09V14.5242H149.463C151.992 14.5242 153.27 13.3183 153.27 11.3312C153.27 9.30615 151.992 8.12721 149.463 8.12721Z"
        fill="#2196F3"
      />
    </svg>
  </div>
));

export default React.memo(Logo);
