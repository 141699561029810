import React, { ReactElement } from "react";

import Error from "@/components/Error";

function Custom404Page() {
  return (
    <Error 
      statusCode={404}
      title="Не найдена страница"
    />
  )
}
Custom404Page.displayName = "404";

Custom404Page.getLayout = function getLayout(page: ReactElement) {
  return page;
}

export default Custom404Page;